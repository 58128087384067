







































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import ContactPanel from '@/components/ContactPanel.vue';

@Component({
  components: {
    ContactPanel
  }
})
export default class P0708 extends Vue {
  //新規登録
  private signup() {
    this.$auth.loginWithRedirect({
      initialDisplay: 'signup'
    });
  }

  readonly SERVICE_SLUG = 'odakyu-energy';
  //タブ定数
  readonly TAB_SCENE = 'Scene';
  readonly TAB_FEATURE = 'Feature';

  //現在のタブの値
  tabStatus = this.TAB_FEATURE;

  //サービスのURL
  readonly USE_SERVICE_URL = 'https://odakyu-energy.jp/license/';

  //QA表示・非表示トグル動作（DOMを直接操作している）
  toggleAnswer(e: Event) {
    let target = e.target as HTMLElement;
    let targetParent = target.parentNode as HTMLElement;
    let targetBox = targetParent.nextSibling as HTMLElement;
    if (target.getAttribute('aria-expanded') === 'true') {
      target.setAttribute('aria-expanded', 'false');
      targetBox.setAttribute('aria-hidden', 'true');
    } else {
      target.setAttribute('aria-expanded', 'true');
      targetBox.setAttribute('aria-hidden', 'false');
    }
  }
}
